import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';

import Mission from './components/Mission/Mission';
import Stats from './components/Stats/stats';
import Profiles from './components/Profiles/Profiles';
import Footer from './components/Footer/Footer';
import Contact from './components/Contact/Contact';
import Quality from './components/Quality/quality';
import Carousel from './components/Carousel/Carousel';
import { useEffect, useRef } from 'react';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-FCGH8KW7FP'); 

function App() {
    return (
        <div className='site-wrapper'>
            <Navbar />
            <Home />
            <Mission />
            <Quality />
            <Stats/>
            <Profiles />
            <Contact />
            <Footer />
          
        </div>
    );
}

export default App;