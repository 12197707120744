import React from 'react';
import { Element } from 'react-scroll';
import { useState, useRef } from 'react';
import Immediate from '../../images/SVGsforCrbon/Imme-CrbonLabs-July 23.svg';
import Provable from '../../images/SVGsforCrbon/Prov-CrbonLabs-July 23.svg';
import Permanent from '../../images/SVGsforCrbon/Perm-CrbonLabs-July 23.svg';
import Additional from '../../images/SVGsforCrbon/Add-CrbonLabs-July 23.svg';
import Verified from '../../images/SVGsforCrbon/Veri-CrbonLabs-July 23.svg';
import Local from '../../images/SVGsforCrbon/Local-CrbonLabs-July 23.svg';
import { useIsVisible } from '../../Hooks/IsVisible';

import './quality.css';


const QualityModule = ({ index, animationPlayed, setAnimationPlayed, header, imageSrc, description }) => {
  const visibleRef = useRef();
  const isVisible = useIsVisible(visibleRef);


  const handleAnimationEnd = () => {
    setAnimationPlayed((prevState) => ({ ...prevState, [index]: true }));
  };

  return (
    <div
      className={`quality-module ${!animationPlayed[index] && isVisible
        ? index % 2 === 0
          ? 'fadeRight'
          : 'fadeLeft'
        : animationPlayed[index]
          ? 'animation-done'
          : ''
        }`}
      ref={visibleRef}
      onAnimationEnd={handleAnimationEnd}
    >

      <div className='module-img-text-wrapper'>
        <div className='image-wrapper'>
          <img className='circle-img' src={imageSrc} alt={header} />
        </div>
        <div className='module-text-container'>
          <div className='module-header'>{header}</div>
          <div className='module-text'>{description}</div>
        </div>
      </div>
    </div>
  );
};

const Quality = () => {
  const [animationPlayed, setAnimationPlayed] = useState({});
  const Modules = [
    {
      header: 'Immediate',
      description: "It’s about acting now. GHG emissions are eliminated in months, not years. Every day is crucial in restoring our environment.",
      imageSrc: Immediate
    },
    {
      header: 'Provable',
      description: "With each carbon offset’s serial number, you can easily trace the unique well location, specific work done, and actual GHG emission measurements before and after decommissioning. It's transparent, traceable, and measurable.",
      imageSrc: Provable
    },
    {
      header: 'Permanent',
      description: "Eliminating GHG emissions is crucial, but making sure that the reductions are permanent is equally important. With each well fully decommissioned, the elimination of GHG emissions becomes permanent, providing long-term benefits for the environment.",
      imageSrc: Permanent
    },
    {
      header: 'Additional',
      description: "Crbon Labs' projects make it possible to take action towards the elimination of GHG emissions, which would not have been possible otherwise. Together, we can actively contribute to creating a sustainable future and take pride in knowing that our actions will have a positive impact on the environment.",
      imageSrc: Additional
    },
    {
      header: 'Authentic',
      description: "With each and every carbon credit validated, verified and assigned a serial number by independent 3rd party accredited organizations, you can be assured of their authenticity and the positive impact they will have on GHG emissions.",
      imageSrc: Verified
    },
    {
      header: 'Local',
      description: "Crbon Labs' projects also offer both social and ecological benefits. We hire people from local communities, reduce local GHG emissions, and restore land to its natural state through practices like re-wilding and agriculture, or installation of clean renewable energy.",
      imageSrc: Local
    }
  ];



  return (
    <Element name="quality" className="Quality section-padding">
      <div className='quality-page wrapper'>
        <div className="quality-heading-area">
          <h1 className='heading-primary'>Quality Matters</h1>
          <div className="quality-text-1">
            We’re collaborating with reputable experts and stakeholders to drive trusted sustainability solutions.
          </div>
          <div className="quality-text-2">
            It’s the how of what we do that delivers quality.
          </div>
          <div className="quality-text-3">
            Whether you’re a buyer of offsets, a local community member, an investor or producer, quality matters. Crbon Labs <span className='trademark-span'>High Fidelity Crbon™</span> offsets reflect our commitment to the exceptional quality of our systems, processes, and partners.
          </div>


        </div>
        <div className='quality-modules'>
          {Modules.map((module, index) => (
            <QualityModule
              key={index}
              index={index}
              animationPlayed={animationPlayed}
              setAnimationPlayed={setAnimationPlayed}
              header={module.header}
              imageSrc={module.imageSrc}
              description={module.description}

            />
          ))}
        </div>
      </div>
    </Element>
  );
};

export default Quality;