import { Element } from 'react-scroll';
import { useState, useEffect, useRef } from 'react';


import './home.css'
const Home = () => {
    return (
        <Element name='home' className='home-container'>
            <div className='home-text-container'>
                <p className='text-primary'>
                    Emerging leaders in lowering greenhouse gas emissions by decarbonizing oil and gas production.
                </p>
            </div>

        </Element>
    );
};

export default Home;